import React from "react";

const Footer = () => {
  return (
    <div className="border-white border-t-[1px] w-full mt-3 p-3 text-center text-xs font-sans text-white ">
      Adira AI is a Proprietory Legal Based Generative AI developed by CLAW
      Legal Tech
    </div>
  );
};

export default Footer;
